<template>
    <div class="slimhome">
        <div class="container">
            <Map :idMap='idMap' ref="boxMap"></Map>
            <NavBottom></NavBottom>
            <div class="showCascader">
                <el-cascader
                placeholder="请选择站点名称"
                :options="options"
                @change="changeCascader"
               
                 :show-all-levels="false"
                 clearable
                filterable></el-cascader>
                <!-- <el-button class="btn item-primary" type="primary" @click="searchBtn">查询</el-button> -->
            </div>
            <div class="warn-box c-center" @click="show = !show" v-if="(warnList.length+infoList.length)!=0">
                <img class="warn-light" src="../assets/img/warn-bg.png">
                <img v-if="warnList.length > 0" class="warn-state" src="../assets/img/warning.gif">
                <img v-else class="warn-state" src="../assets/img/unwarn.png">
            </div>
            <div class="warnbox" :class=" warnList.length > 0?'warn-ani':'open-ani'" v-if="(warnList.length+infoList.length)!=0" v-show="show">
                <div class="warnhead">
                    <div class="c-center">
                        <img v-if="warnList.length>0" src="../assets/img/warn_light.png" class="warn-icon">
                        <span>报警信息（<span v-if="warnList.length>0" style="color: red;">{{warnList.length}}</span><span v-else >{{warnList.length}}</span>/{{infoList.length+warnList.length}}）</span>
                    </div>
                    <img class="warn-close" src="../assets/img/warn_close.png" @click="show = false">
                </div>
                <div class="content">
                    <!-- 报警列表 -->
                    <div class="alarmbox">
                        <template v-if="warnList.length > 5">
                            <vueSeamlessScroll :data="warnList" :class-option="scrollOption" class="hidden">
                                <div class="info-item" v-for="item in warnList" :key="item.id">
                                    <div>
                                        <span>{{ item.siteName }}</span>
                                        <span v-if="item.type === 1">（男厕氨气报警）</span>
                                        <span v-if="item.type === 2">（女厕氨气报警）</span>
                                        <span v-if="item.type === 3">（男厕硫化氢报警）</span>
                                        <span v-if="item.type === 4">（女厕硫化氢报警）</span>
                                        <span v-if="item.type === 5">（满载）</span>
                                    </div>
                                    <el-button type="danger" size="mini" plain @click="$router.push({name:'alarmrecord', params:{userId: item.id,status: item.siteType,typeID: item.status}})"
                                        >立即处置</el-button
                                    >
                                </div>
                            </vueSeamlessScroll>
                        </template>
                        <template v-else>
                            <div class="info-item" v-for="item in warnList" :key="item.id">
                                    <div>
                                        <span>{{ item.siteName }}</span>
                                        <span v-if="item.type === 1">（男厕氨气报警）</span>
                                        <span v-if="item.type === 2">（女厕氨气报警）</span>
                                        <span v-if="item.type === 3">（男厕硫化氢报警）</span>
                                        <span v-if="item.type === 4">（女厕硫化氢报警）</span>
                                        <span v-if="item.type === 5">（满载）</span>
                                    </div>
                                    <el-button type="danger" size="mini" plain @click="$router.push({name:'alarmrecord', params:{userId: item.id,status: item.siteType,typeID: item.status}})"
                                        >立即处置</el-button
                                    >
                                </div>
                        </template>
                        <template v-if="warnList.length < 5">
                                <!-- 已处理列表大于剩下空间时滚动 -->
                                <template v-if="infoList.length > (5 - warnList.length)">
                                    <vueSeamlessScroll :data="infoList" :class-option="scrollOption" class="auto hidden">
                                        <div class="info-item" v-for="item in infoList" :key="item.id">
                                            <div>
                                                <span>{{ item.siteName }}</span>
                                        <span v-if="item.type === 1">（男厕氨气报警）</span>
                                        <span v-if="item.type === 2">（女厕氨气报警）</span>
                                        <span v-if="item.type === 3">（男厕硫化氢报警）</span>
                                        <span v-if="item.type === 4">（女厕硫化氢报警）</span>
                                        <span v-if="item.type === 5">（满载）</span>
                                            </div>
                                            <el-button type="primary" size="mini" plain @click="$router.push({name:'alarmrecord', params:{userId: item.id,status: item.siteType,typeID: item.status}})"
                                                >查看详情</el-button
                                            >
                                        </div>
                                    </vueSeamlessScroll>
                                </template>
                                <template v-else>
                                    <div class="info-item" v-for="item in infoList" :key="item.id">
                                        <div>
                                            <span>{{ item.siteName }}</span>
                                        <span v-if="item.type === 1">（男厕氨气报警）</span>
                                        <span v-if="item.type === 2">（女厕氨气报警）</span>
                                        <span v-if="item.type === 3">（男厕硫化氢报警）</span>
                                        <span v-if="item.type === 4">（女厕硫化氢报警）</span>
                                        <span v-if="item.type === 5">（满载）</span>
                                        </div>
                                        <el-button type="primary" size="mini" plain @click="$router.push({name:'alarmrecord', params:{userId: item.id,status: item.siteType,typeID: item.status}})"
                                            >查看详情</el-button
                                        >
                                    </div>
                                </template>
                            </template>
                    </div>
                    <div class="alarmbox">

                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Map from "./map/Map";
import NavBottom from "./NavBottom"
import axios from "../request"
import vueSeamlessScroll from 'vue-seamless-scroll'

export default {
    name: "SlimHome",
    components: { Map, NavBottom, vueSeamlessScroll },
    data(){
        return {
            show: true,
            options:[],
            valueMap:{},
            idMap:{},
            washroomAlarmList: [],
            washroomUnAlarmList: [],
            stationAlarmList: [],
            stationUnAlarmList: [],
            scrollOption: {
                limitMoveNum: 1,
            },
        }
    },
    computed: {
        warnList(){
            return this.washroomAlarmList.concat(this.stationAlarmList)
        },
        infoList(){
            return this.washroomUnAlarmList.concat(this.stationUnAlarmList)
        }
    },
    methods: {
        // 获取公厕实时报警信息
        async getWashRoomAlarm(){
            let data = await axios.get("/data/index/alarmRecord/findList", {params: {siteType: 1}})
            console.log(data)
            if(data){
                this.washroomAlarmList = data.noDisposeLists
                this.washroomUnAlarmList = data.disposeLists
            }
        },
        // 获取公厕实时报警信息
        async getSearch(){
            let data = await axios.get("/data/index/find/getAllSite")
            if(data){
                console.log(data);
                this.options=data
            }
        },
        searchBtn(){
            this.idMap=this.valueMap
            
        },
        changeCascader(val){
            console.log(val)
        
            if (val.length ==0) {
                console.log('66666666')
                this.idMap = false
             
            }else {
                console.log(this.options)
                let name=this.options.find(res=>{
                    return val[0]==res.value
                })
                this.valueMap=name.children.find(res=>{
                    return val[1]==res.value
                })
                console.log(this.valueMap );
                this.idMap=this.valueMap
            }
           
        },
    
        // 获取转运站实时报警信息
        async getStationAlarm(){
            let data = await axios.get("/data/index/alarmRecord/findList", {params: {siteType: 2}})
            if(data){
                this.stationAlarmList = data.noDisposeLists
                this.stationUnAlarmList = data.disposeLists
            }
        },
    },
    async created(){
        this.getWashRoomAlarm()
        this.getStationAlarm()
        this.getSearch()
        this.$nextTick(function() {
            console.log(this.$refs.boxMap)
            console.log(this.$refs.boxMap.$el.children[4].style)
            this.$refs.boxMap.$el.children[4].style.left = "850px";
            this.$refs.boxMap.$el.children[4].style.top = "5%";
            this.$refs.boxMap.$el.children[4].style.position = "absolute";
        })
    }
};
</script>

<style scoped lang="scss">
@keyframes warning {
    from{
        border-color: #FF4940;
    }
    to{
        border-color: #ffc107;
    }
}
.warn-ani{
    border: 4px solid #FF4940;
    animation: warning .1s linear 0s infinite alternate;
    
}
.open-ani{
     display: none;
     border: 4px solid grey;
}
.slimhome {
    height: 100%;
    display: flex;
    flex-direction: column;
}
.container {
    position: relative;
    flex: auto;
}
#slimhome-map {
    height: 100%;
}
.warn-icon{
    margin-right: 10px;
}
.warnbox{
    display: flex;
    flex-direction: column;
    padding: 10px;
    width: 330px;
    height: 248px;
    border: 4px solid #FF4940;
    background: #FFFFFF;
    border-radius: 8px;
    position: absolute;
    right: 20px;
    bottom: 60px;
}
.warnhead{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.warn-close{
    cursor: pointer;
}
.content{
    margin-top: 10px;
    overflow: hidden;
    flex: 1;
}
.info-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px 0;
    border-top: solid 1px #eee;
    white-space: nowrap;
    font-size: 14px;
}
.hidden{
    overflow: hidden;
}
.warn-box{
    cursor: pointer;
    position: absolute;
    right: 20px;
    top: 50%;
}
.warn-state{
    position: absolute;
}
//下拉搜索
.showCascader{
    position:absolute;
    top:  10px;
    left: 4%;
}
.btn{
    width: 75px;
    margin-left: 20px;
}
.el-cascader{
    width: 250px;
}
</style>
